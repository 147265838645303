import {useRef} from "react";

function SettingsForm(props) {
  const raceTypeRef = useRef();
  const imageLinkRef = useRef();
  const showAnalysisRef = useRef();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  function submitFormHandler(event) {
    event.preventDefault();

    const raceSettingsData = {
      raceId: props.raceSettings.raceId,
      raceType: raceTypeRef.current.value,
      imageLink: imageLinkRef.current.value,
      showAnalysis: showAnalysisRef.current.checked
    };
    console.log("posting raceSettings");
    fetch(serverUrl + "/rest/raceSettings/" + props.raceSettings.raceId, {
      method: "POST",
      body: JSON.stringify(raceSettingsData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  return (
    <div className="mx-3">
      <form onSubmit={submitFormHandler}>
        <div className="input-group mb-3">
          <span className="input-group-text">Välj typ av lopp:</span>
          <select className="form-control" defaultValue={props.raceSettings.raceType} ref={raceTypeRef}>
            <option>Sprint</option>
            <option>Distans</option>
            <option>Jaktstart</option>
          </select>
        </div>
        <div className="input-group mb-3">
          <label htmlFor="imageLink" className="input-group-text">
            Länk till bild för resultatsidan:
          </label>
          <input
            id="imageLink"
            type="text"
            defaultValue={props.raceSettings.imageLink}
            className="form-control"
            ref={imageLinkRef}
          />
        </div>
        <div className="form-check mb-4">
          <label htmlFor="analysisCheckbox" className="form-check-label">Visa analyssida</label>
          <input id="analysisCheckbox" defaultChecked={props.raceSettings.showAnalysis} className="form-check-input" type="checkbox" ref={showAnalysisRef} />
        </div>

        <button className="btn btn-primary" type="submit" value="Spara">
          Spara
        </button>
      </form>
    </div>
  );
}

export default SettingsForm;
