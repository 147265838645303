import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const serverUrl = process.env.REACT_APP_SERVER_URL;

function TimingPage(props) {
  const [participants, setParticipants] = useState([]);
  
  useEffect(() => {
    props.raceId && fetchParticipants();
  }, [props.raceId]);

  function fetchParticipants() {
    fetch(serverUrl + "/rest/participants/" + props.raceId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setParticipants(data);
      });
  }

  function sendTime(participant, before) {
    console.log("Sending (timing) post for bib:" + participant.bib);
    fetch(serverUrl + "/rest/updateTime/" + props.raceId + "/" + participant.bib + "/" + before, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      fetchParticipants();
    });
  }

  return (
    <div className="mx-3">
      {<table className="table table-sm table-hover">
        <thead>
          <tr className="row mx-0">
            <th className="col-1 text-end text-responsive pe-3"> Nr</th>
            <th className="col-3 text-responsive"> Namn</th>
            <th className="col-1 text-responsive"> In</th>
            <th className="col-1 text-responsive"> Ut</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => {
            return (
              <tr className="row mx-0" key={participant.bib}>
                <td className="col-1 text-end text-responsive pe-3">
                  <span> {participant.bib} </span>
                </td>
                <td className="col-3 text-responsive">
                  <span>{participant.name}</span>
                </td>
                <td className="col-1 text-responsive">
                  <span>{participant.splitTimesBefore && participant.splitTimesBefore.length}</span>
                </td>
                <td className="col-1 text-responsive">
                  <span>{participant.splitTimesAfter && participant.splitTimesAfter.length}</span>
                </td>
                <td className="col-2 text-responsive">
                  <button onClick={() => sendTime(participant, true)}
                    className="btn color-0 text-responsive padding-responsive col-12 gap-1">In</button>
                </td>
                <td className="col-2 text-responsive">
                  <button onClick={() => sendTime(participant, false)}
                    className="btn color-5 text-responsive padding-responsive col-12 gap-1">Ut</button>
                </td>
                <td className="col-2">
                    <Link className="btn btn-primary text-responsive padding-responsive col-12 gap-1" to={'/editTimes/' + participant.bib}>Ändra</Link>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>}
    </div>
  );
}

export default TimingPage;
