import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function EditMissesForm(props) {

  const additionalSecondsRef = useRef();
  const missesRef = useRef();
  const missesStandingRef = useRef();
  const commentRef = useRef();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

    function submitFormHandler(event) {
        event.preventDefault();
    
        const missReportData = {
            bib: props.missReport.bib,
            misses: missesRef.current.value,
            missesStanding: missesStandingRef.current.value,
            comment: commentRef.current.value,
            additionalSeconds: additionalSecondsRef.current.value
        }
        console.log("posting edited miss data")
        fetch(serverUrl + "/rest/editMisses/" + props.raceId + "/" + props.missReport.bib, {
            method: 'POST',
            body: JSON.stringify(missReportData),
            headers: {
                'Content-Type': "application/json"
            }
        }).then((response) => {
            navigate("/registerMisses")
        })
    }

  return (
    <form onSubmit={submitFormHandler}>

      <p>Uppdatera bommar för nr {props.missReport.bib}</p>
      <div className="input-group mb-3">
          <label htmlFor="misses" className="input-group-text">Bommar Liggande</label>
          <input id="misses" type="number" defaultValue={props.missReport.misses} className="form-control" ref={missesRef} />
      </div>
      <div className="input-group mb-3">
          <label htmlFor="missesStanding" className="input-group-text">Bommar Stående</label>
          <input id="misses" type="number" defaultValue={props.missReport.missesStanding} className="form-control" ref={missesStandingRef} />
      </div>

      <div className="input-group mb-3">
          <label htmlFor="manualAdditionalTime" className="input-group-text">Manuellt tidstillägg i sekunder (avdrag med -)</label>
          <input id="manualAdditionalTime" type="number" defaultValue={props.missReport.additionalSeconds} className="form-control" ref={additionalSecondsRef} />
      </div>
      <div className="input-group mb-3">
          <label htmlFor="comment" className="input-group-text">Kommentar</label>
          <input id="comment" type="text" defaultValue={props.missReport.comment} className="form-control" ref={commentRef} />
      </div>
      <button className="btn btn-primary" type="submit" >Uppdatera</button>
    </form>
  );
}

export default EditMissesForm;
