import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditTimesForm from "../EditTimesForm";

const serverUrl = process.env.REACT_APP_SERVER_URL;

function EditTimesPage(props) {
  const [participant, setParticipant] = useState();
  let { bib } = useParams();

  useEffect(() => {
    props.raceId &&
      fetch(serverUrl + "/rest/participants/" + props.raceId + "/" + bib)
        .then((response) => {
          return response.json();
        })
        .then((participant) => {
          setParticipant(participant);
        });
  }, [props.raceId, bib]);

  return <div className="mx-3">{participant && <EditTimesForm participant={participant} raceId={props.raceId} />}</div>;
}

export default EditTimesPage;
