import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditPenaltiesForm from "../EditPenaltiesForm";

function EditPenaltiesPage(props) {
  const [penaltyReport, setPenaltyReport] = useState();
  let { bib } = useParams();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  function createPenaltyReport(participant) {
    return {
      "bib": participant.bib,
      "penalties" : participant.penalties,
    }  
  }

  useEffect(() => {
    props.raceId &&
      fetch(serverUrl + "/rest/participants/" + props.raceId + "/" + bib)
        .then((response) => {
          return response.json();
        })
        .then((participant) => {
          setPenaltyReport(createPenaltyReport(participant));
        });
  }, [props.raceId, bib]);

  return <div>{penaltyReport && <EditPenaltiesForm penaltyReport={penaltyReport} raceId={props.raceId} />}</div>;
}

export default EditPenaltiesPage;
