import { useEffect, useState } from "react";
import SettingsForm from "../SettingsForm";

function SettingsPage(props) {
  const [raceSettings, setRaceSettings] = useState();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    props.raceId &&
      fetch(serverUrl + "/rest/raceSettings/" + props.raceId)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setRaceSettings(data);
        });
  }, [props.raceId]);

  return <div>{raceSettings && <SettingsForm raceSettings={raceSettings} />}</div>;
}

export default SettingsPage;
