import React from "react";
import { Link } from "react-router-dom";

function handleCollapse() {
  console.log("handleCollapse");
  var nav = document.getElementById("navbarSupportedContent");
  var btn = document.getElementById("navbarBtn");
  nav.classList.remove("show");
  btn.classList.add("collapsed");
}


function MainNavigation(props) {
  return (
    <div>
      <div className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary py-0">
        <div className="navbar-brand mx-2 text-responsive-lg">
          {props.activeRaceName}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          id="navbarBtn"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse mx-2" id="navbarSupportedContent" >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to="/" onClick={() => handleCollapse()}>Start</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/settings" onClick={() => handleCollapse()}>Inställningar</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/klassSettings" onClick={() => handleCollapse()}>Klassinställningar</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/registerMisses" onClick={() => handleCollapse()}>Bommar</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/registerPenalties" onClick={() => handleCollapse()}>Straffrundor</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/timing" onClick={() => handleCollapse()}>Mellantider</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/results" onClick={() => handleCollapse()}>Resultat</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MainNavigation;
