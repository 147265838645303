import { useRef, useState } from "react";
import { useSearchParams} from 'react-router-dom';

function StartPage(props) {
  const [loadedRaces, setLoadedRaces] = useState([]);
  const [initializing, setInitializing] = useState(false);
  const [queryParams] = useSearchParams();
  const tokenInputRef = useRef();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  function handleSubmit(event) {
    event.preventDefault();
    props.setNytatimeToken(tokenInputRef.current.value)
    console.log("settings nytatimeToken to" + tokenInputRef.current.value)
    fetch(serverUrl + "/rest/races/" + tokenInputRef.current.value)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedRaces(data);
        console.log(data);
      });
  }

  function initNytaTimeRace(raceId) {
    setInitializing(true)
    fetch(serverUrl + "/rest/initRace/" + raceId)
    .then((response) => {
      return response
    })
    .then((data) => {
      setInitializing(false)
    })
  }

  function selectRace(raceId, raceName) {
    props.setActiveRaceId(raceId)
    props.setActiveRaceName(raceName)
    initNytaTimeRace(raceId)
  }

  return (
    <div className="container-fluid m-1">
      <form onSubmit={handleSubmit}>
        <div className="input-group mb-3">
          <label htmlFor="token" className="text-responsive input-group-text">
            NytaTime token:
          </label>
          <input id="token" type="text" ref={tokenInputRef} defaultValue={queryParams.get('token') || props.nytatimeToken} className="form-control" />
        </div>
        <button className="btn btn-primary">Hämta mina tävlingar</button>
      </form>

      <hr/>
      {initializing && <p className="text-danger">Hämtar tävlingsinfo, vänta...</p>}
      <table className="table table-light mt-5">
        <tbody>
          {loadedRaces.map((race) => {
            return (
              <tr key={race.id}>
                <td>{race.name}</td>
                <td>
                  <button onClick={() => selectRace(race.id, race.name)} className="btn btn-primary py-0">
                    Välj
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>Instruktioner för biathlontiming.se finns <a href="https://docs.google.com/document/d/1mlG5knH-ndgEvF60a964_sqsuN9CiVNu9ruUa06zIMM/edit?usp=sharing">här</a></p>
      <p>Mellan tävlingar vilar ibland biathlontiming.se för att spara på server-kostnader. Då kan det ta 1 minut innan allt kommer igång.</p>
    </div>
  );
}

export default StartPage;
