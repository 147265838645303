import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import StartPage from "./components/pages/StartPage";
import RegisterMissesPage from "./components/pages/RegisterMissesPage";
import RegisterPenaltiesPage from "./components/pages/RegisterPenaltiesPage";
import EditMissesPage from "./components/pages/EditMissesPage";
import EditPenaltiesPage from "./components/pages/EditPenaltiesPage";
import EditKlassPage from "./components/pages/EditKlassPage";
import EditTimesPage from "./components/pages/EditTimesPage";
import SettingsPage from "./components/pages/SettingsPage";
import KlassSettingsPage from "./components/pages/KlassSettingsPage";
import TimingPage from "./components/pages/TimingPage";
import ResultsPage from "./components/pages/ResultsPage";
import MainNavigation from "./components/MainNavigation";

function App() {
  const [activeRaceId, setActiveRaceId] = useState("");
  const [activeRaceName, setActiveRaceName] = useState("");
  const [nytatimeToken, setNytatimeToken] = useState("");

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <MainNavigation activeRaceName={activeRaceName} />
        <p className="py-3" />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <StartPage
                nytatimeToken={nytatimeToken}
                setNytatimeToken={setNytatimeToken}
                setActiveRaceId={setActiveRaceId}
                setActiveRaceName={setActiveRaceName}
              />
            }
          />
          <Route path="/registerMisses" exact element={<RegisterMissesPage raceId={activeRaceId} />} />
          <Route path="/registerPenalties" exact element={<RegisterPenaltiesPage raceId={activeRaceId} />} />
          <Route path="/editMisses/:bib" element={<EditMissesPage raceId={activeRaceId} />} />
          <Route path="/editTimes/:bib" element={<EditTimesPage raceId={activeRaceId} />} />
          <Route path="/editPenalties/:bib" element={<EditPenaltiesPage raceId={activeRaceId} />} />
          <Route path="/settings" exact element={<SettingsPage raceId={activeRaceId} />} />
          <Route path="/klassSettings" exact element={<KlassSettingsPage raceId={activeRaceId} />} />
          <Route path="/editKlasses/:klassId" exact element={<EditKlassPage raceId={activeRaceId} />} />
          <Route path="/timing" exact element={<TimingPage raceId={activeRaceId} />} />
          <Route path="/results" exact element={<ResultsPage raceId={activeRaceId} />} />
          <Route path="/results/:resultsRaceId" element={<ResultsPage raceId={activeRaceId} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
