import {useRef} from "react";
import {useNavigate} from "react-router-dom";

function EditTimesForm(props) {

    const timeRefBefore = useRef();
    const timeRefAfter = useRef();
    const navigate = useNavigate();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    
    function submitFormHandler(event) {
        event.preventDefault();

        console.log("posting edited timing data")
        fetch(serverUrl + "/rest/editTimes/" + props.raceId + "/" + props.participant.bib, {
            method: 'POST',
            body: JSON.stringify({
                timesStringBefore: timeRefBefore.current.value,
                timesStringAfter: timeRefAfter.current.value,
                bib: props.participant.bib
            }),
            headers: {
                'Content-Type': "application/json"
            }
        }).then((response) => {
          navigate("/timing")
        })
    }

    return (
        <form onSubmit={submitFormHandler}>
            <p>Uppdatera mellantider för nr {props.participant.bib}</p>
            <p>Tiderna är i sekunder från start separerade med ,</p>
            <div className="input-group mb-3">
                <label htmlFor="timesBefore" className="input-group-text">Före skjutvall</label>
                <input id="timesBefore" type="text" defaultValue={props.participant.splitTimesBefore}
                       className="form-control"
                       ref={timeRefBefore}/>
            </div>
            <div className="input-group mb-3">
                <label htmlFor="timesAfter" className="input-group-text">Efter skjutvall</label>
                <input id="timesAfter" type="text" defaultValue={props.participant.splitTimesAfter}
                       className="form-control"
                       ref={timeRefAfter}/>
            </div>

            <button className="btn btn-primary" type="submit">Uppdatera</button>
        </form>
    );
}

export default EditTimesForm;
