import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function EditPenaltiesForm(props) {
  const penaltiesRef = useRef();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  function submitFormHandler(event) {
    event.preventDefault();

    const penaltyReportData = {
      bib: props.penaltyReport.bib,
      penalties: penaltiesRef.current.value,
    };
    console.log("posting edited penalty data");
    fetch(serverUrl + "/rest/editPenalties/" + props.raceId + "/" + props.penaltyReport.bib, {
      method: 'POST',
      body: JSON.stringify(penaltyReportData),
      headers: {
        'Content-Type': "application/json"
      }
    }).then((response) => {
      navigate("/registerPenalties");
    })
  }

  return (
    <div>
      <p>Ändra straffrundor</p>
      <form onSubmit={submitFormHandler}>
        <p>Uppdatera straffrundor för nr {props.penaltyReport.bib}</p>
        <div className="input-group mb-3">
          <label htmlFor="penalties" className="input-group-text">
            Straffrundor
          </label>
          <input
            id="penalties"
            type="number"
            defaultValue={props.penaltyReport.penalties}
            className="form-control"
            ref={penaltiesRef}
          />
        </div>

        <input type="submit" value="Uppdatera" />
      </form>
    </div>
  );
}

export default EditPenaltiesForm;
