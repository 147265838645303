 import {useRef} from "react";
 import {useNavigate} from "react-router-dom";

function KlassSettingsForm(props) {
  const secondsPerMissRef = useRef();
  const sortByBibRef = useRef();
  const hideTimesRef = useRef();
  const hideOfficialResultsRef = useRef();
  const navigate = useNavigate();

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  function submitFormHandler(event) {
    event.preventDefault();

    const klassSettingsData = {
      secondsPerMiss: secondsPerMissRef.current.value,
      hideOfficialResults: hideOfficialResultsRef.current.checked,
      sortByBib: sortByBibRef.current.checked,
      hideTimes: hideTimesRef.current.checked
    };
    console.log("posting klassSettings");
    fetch(serverUrl + "/rest/klassSettings/" + props.raceId + "/" + props.klassId, {
      method: "POST",
      body: JSON.stringify(klassSettingsData),
      headers: {
        "Content-Type": "application/json",
      },
  }).then((response) => {
    navigate("/klassSettings");
  });
  }

  return (
    <div className="mx-3">
      <form onSubmit={submitFormHandler}>
        <div className="input-group mb-3">
          <label htmlFor="timeForMiss" className="input-group-text">
            Tidstillägg per bom (sekunder):
          </label>
          <input
              id="timeForMiss"
              type="number"
              defaultValue={props.klassSettings.secondsPerMiss}
              className="form-control"
              ref={secondsPerMissRef}
          />
        </div>

        <div className="form-check mb-4">
          <label htmlFor="hideOfficialResultsCheckbox" className="form-check-label">Dölj på publik resultatsida</label>
          <input id="hideOfficialResultsCheckbox" defaultChecked={props.klassSettings.hideOfficialResults} className="form-check-input" type="checkbox" ref={hideOfficialResultsRef} />
        </div>
        <div className="form-check mb-4">
          <label htmlFor="sortByBibCheckbox" className="form-check-label">Sortera resultat på startnummer</label>
          <input id="sortByBibCheckbox" defaultChecked={props.klassSettings.sortByBib} className="form-check-input" type="checkbox" ref={sortByBibRef} />
        </div>
        <div className="form-check mb-4">
          <label htmlFor="hideTimesCheckbox" className="form-check-label">Dölj tider i resultat</label>
          <input id="hideTimesCheckbox" defaultChecked={props.klassSettings.hideTimes} className="form-check-input" type="checkbox" ref={hideTimesRef} />
        </div>

        <button className="btn btn-primary" type="submit" value="Spara">
          Spara
        </button>
      </form>
    </div>
  );
}

export default KlassSettingsForm;
