import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import KlassSettingsForm from "../KlassSettingsForm";

function KlassSettingsPage(props) {
  const [klassSettings, setKlassSettings] = useState();
  let { klassId } = useParams();

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    props.raceId &&
      fetch(serverUrl + "/rest/klassSettings/" + props.raceId + "/" + klassId)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setKlassSettings(data);
        });
  }, [props.raceId]);

    return <div>
        <div>{klassSettings && <KlassSettingsForm raceId={props.raceId} klassId={klassId} klassSettings={klassSettings}/>}</div>
    </div>;
}

export default KlassSettingsPage;
