import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function KlassSettingsPage(props) {
  const [klasses, setKlasses] = useState();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    props.raceId &&
      fetch(serverUrl + "/rest/klassSettings/" + props.raceId)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setKlasses(data);
        });
  }, [props.raceId]);

  return (
    <div className="mx-3">
      <h3>Klassinställningar</h3>
      {klasses &&
        klasses.map((klass) => {
          return (
            <div key={klass.klassId}>
              <Link to={"/editKlasses/" + klass.klassId}>{klass.klassName}</Link>
            </div>
          );
        })}
    </div>
  );
}

export default KlassSettingsPage;
