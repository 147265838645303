import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditMissesForm from "../EditMissesForm";

function EditMissesPage(props) {
  const [missReport, setMissReport] = useState();
  let { bib } = useParams();
  const serverUrl = process.env.REACT_APP_SERVER_URL;


  function createMissReport(participant) {
    return {
      "bib": participant.bib,
      "misses" : participant.misses,
      "missesStanding" : participant.missesStanding,
      "additionalSeconds" : participant.manualAdditionalTime,
      "comment" : participant.comment
    }  
  }

  useEffect(() => {
    props.raceId &&
      fetch(serverUrl + "/rest/participants/" + props.raceId + "/" + bib)
        .then((response) => {
          return response.json();
        })
        .then((participant) => {
          setMissReport(createMissReport(participant));
        });
  }, [props.raceId, bib]);

  return <div className="mx-3">{missReport && <EditMissesForm missReport={missReport} raceId={props.raceId} />}</div>;
}

export default EditMissesPage;
